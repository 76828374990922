.card-calendar {
    height: 100%;
}

.card-calendar .ant-card-body {
    height: 100%;
}

.rbc-event {
    background-color: #d6e7ff !important;
    color: black !important;
    border: 1px solid #0d6efd !important;
}

.rbc-event .rbc-selected {
    background-color: #d6e7ff !important;
    color: black !important;
    border: 1px solid #0d6efd !important;
}

.rbc-toolbar button {
    color: #1677ff;
    border: 1px solid #1677ff !important;
    background-color: white;
}

.rbc-toolbar button:hover {
    background-color: #d6e7ff !important;
}

.rbc-toolbar button:active {
    background-color: #d6e7ff !important;
}

.rbc-toolbar button.rbc-active {
    color: #fff !important;
    background-color: #1677ff !important;
}