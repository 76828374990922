body {
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-layout-sider-zero-width-trigger {
  top: 13px !important;
}

.simi-main-layout {
  height: 100% !important;

  .simi-main-content {
    padding: 24px 16px 0;
    overflow: auto;
    min-height: calc(100vh - 110px);

    .simi-main-content--panel {
      padding: 24px;
    }
  }
}

@media screen and (max-width: 576px) {
  .simi-main-layout {
    .simi-main-content {
      padding: 8px 8px 0;

      .simi-main-content--panel {
        padding: 12px;
      }
    }
  }
}
