.conte {
    height: 100vh;
    position: relative;
  }

.conte-sm {
    height: 50vh;
    position: relative;
}

.iconoload {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
