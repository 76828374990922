.simi-button-success.ant-btn-primary {
    background: #52c41a;
}

.simi-button-success.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: #389e0d;
}

.simi-button-success.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
    background: #389e0d;
}

.simi-button-success.ant-btn-default {
    color: #52c41a;
    border-color: #52c41a;
}

.simi-button-success.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #95de64;
    border-color: #95de64;
}

.simi-button-success.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: #389e0d;
    border-color: #389e0d;
}

.simi-button-success.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
    color: #389e0d;
    border-color: #389e0d;
}

.simi-button-success.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):active {
    color: #389e0d;
    border-color: #389e0d;
}

.simi-button-success.ant-btn-link {
    color: #52c41a;
}

.simi-button-success.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: #389e0d;
    border-color: #389e0d;
}

.simi-button-success.ant-btn-link:not(:disabled):not(.ant-btn-disabled):active {
    color: #389e0d;
    border-color: #389e0d;
}

.simi-button-warning.ant-btn-primary {
    background: #faad14;
}

.simi-button-warning.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: #faad14;
}

.simi-button-warning.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
    background: #d48806;
}

.simi-button-warning.ant-btn-default {
    color: #faad14;
    border-color: #faad14;
}

.simi-button-warning.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #ffd666;
    border-color: #ffd666;
}

.simi-button-warning.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: #d48806;
    border-color: #d48806;
}

.simi-button-warning.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
    color: #faad14;
    border-color: #faad14;
}

.simi-button-warning.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):active {
    color: #d48806;
    border-color: #d48806;
}

.simi-button-warning.ant-btn-link {
    color: #faad14;
}

.simi-button-warning.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: #faad14;
    border-color: #faad14;
}

.simi-button-warning.ant-btn-link:not(:disabled):not(.ant-btn-disabled):active {
    color: #d48806;
    border-color: #d48806;
}
