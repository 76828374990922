.home-container {
    background-image: url('../../image/background.png'); 
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .imagen-inicio {
    /* max-width: 200px;
    width: 30%; */
    height: auto;
    margin-bottom: 20px;
  }
  
  .input-container {
    text-align: center;
    background: rgba(255, 255, 255, 0.8); 
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
  }
  
  .search-input {
    width: 100% !important; 
  }
  
  .header {
    text-align: center;
    color: #fff;
    font-size: 24px;
    background: #001529;
    padding: 10px;
  }
  
  @media (max-width: 768px) {
    .home-container {
      padding: 10px;
    }
  
    .imagen-inicio {
      width: 50%;
      margin-bottom: 10px;
    }
  
    .input-container {
      padding: 15px;
      width: 100%;
    }
  
    .header {
      font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .imagen-inicio {
      width: 70%;
      margin-bottom: 10px;
    }
  
    .input-container {
      padding: 10px;
      width: 100%;
    }
  
    .header {
      font-size: 18px;
    }
  }
  