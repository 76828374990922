.invoice-box {
	max-width: 1500px;
	margin: auto;
	padding: 30px;
	border: 1px solid #eee;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	font-size: 16px;
	line-height: 24px;
	font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
	color: #555;
}

.invoice-box table {
	width: 100%;
	line-height: inherit;
	text-align: left;
}

.invoice-box table td {
	padding: 5px;
	vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
	text-align: left;
}

.invoice-box table tr td:nth-child(2) .topinfor {
	text-align: left;
	width: 50%;
	border: 1px solid black;
	border-collapse: collapse;
}
.invoice-box table tr td:nth-child(2) .topinfol {
	text-align: right;
	width: 50%;
	border: 1px solid black;
	border-collapse: collapse;
}

.invoice-box table tr.top table td {
	padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
	font-size: 45px;
	line-height: 45px;
	color: #333;
}

.invoice-box table tr.information table td {
	padding-bottom: 40px;
}

.invoice-box table tr.heading td {
	background: #eee;
	border-bottom: 1px solid #ddd;
	font-weight: bold;
	text-align: center;
	border: 1px solid black;
	border-collapse: collapse;
	width: 50%;
}

.invoice-box tr.headinginfo td {
	background: #eee;
	border-bottom: 1px solid #ddd;
	font-weight: bold;
	width: "50";
	border: 1px solid black;
	border-collapse: collapse;
}

.invoice-box table tr.details td {
	padding-bottom: 20px;
	text-align: left;
	border: 1px solid black;
	border-collapse: collapse;
}

.invoice-box table tr.detailsinfo td {
	border: 1px solid black;
	border-collapse: collapse;
}

.invoice-box table tr.detailsinfo td.label {
	text-align: center;
	width: 20%;
}

.invoice-box table tr.detailsinfo td.info {
	text-align: center;
	width: 30%;
}

.invoice-box table tr.item td {
	border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
	border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
	border-top: 2px solid #eee;
	font-weight: bold;
}

/** RTL **/
.invoice-box.rtl {
	direction: rtl;
	font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
	text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
	text-align: left;
}

.invoice-box table .vehiculoinfo {
	table-layout: fixed;
	width: 250px;
}

.invoice-box th, td .vehiculoinfo {
	word-wrap: break-word;
}
