td:has(.cell-bg-gray) {
  background: #333333 !important;
  color: white;
}
td:has(.cell-bg-cyan) {
  background: #00cccc !important;
  color: black;
}
td:has(.cell-bg-green) {
  background: #00cc33 !important;
  color: black;
}
td:has(.cell-bg-yellow) {
  background: #ffff00 !important;
  color: black;
}
td:has(.cell-bg-purple) {
  background: #663366 !important;
  color: white;
}
td:has(.cell-bg-brown) {
  background: #663300 !important;
  color: white;
}
td:has(.cell-bg-red) {
  background: #990000 !important;
  color: white;
}
td:has(.cell-bg-lima) {
  background: #ccffcc !important;
  color: black;
}
td:has(.cell-bg-yellow-light) {
  background: #ffcc99 !important;
  color: black;
}
td:has(.cell-bg-pink) {
  background: #ffcccc !important;
  color: black;
}
td:has(.cell-bg-gold) {
  background: #999900 !important;
  color: black;
}
td:has(.cell-bg-white) {
  background: #efefef !important;
  color: black;
}
td:has(.cell-bg-green-dark) {
  background: #006633 !important;
  color: white;
}

.cell-editable {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  cursor: pointer;
}

.cell-editable:hover .icon {
  opacity: 0.6 !important;
}

.cell-editable .icon {
  opacity: 0;
  transition: 0.4s all ease-in-out;
}