
  * {
    margin: 0;
    padding: 0;
  }
  .bodylogin {
    background: url(../image/background.png) no-repeat center center fixed;   /*rgb(0,20,41);*/
    background-size: cover;  /*background: linear-gradient(0deg, rgba(0,20,41,1) 0%, rgba(31,78,102,1) 100%);*/
    position: absolute;
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 1rem;
  }
  
  .main-section {
    margin: auto;
    margin-top: 10%;
    padding: 0;
  
  }
  
  .form-section {
    justify-content: center;
    align-items: center;
    text-align: left;
    font-weight: bold;
    font-size: large;
    color: white;
  }

  .form-section a {
    color: white;
  }
  
  .ini-img {
    margin-top: -50px;
    margin-bottom: 35px;
    margin-left: 0%;
  
  }
  
  .ini-img img {
    width: 300px;
    height: 300px;
  
  }

  /*reset password*/
  

  .resetpassword {
    margin-top: 20%;
    background-color: #eeeeee;
    border-width: 0.2rem 0.2rem 0;
    border-radius: 45px 45px 45px 45px;
    position: relative;
    padding: 1rem;
    border: 0.6rem solid #ececec;
    margin-right: 0;
    margin-left: 0;
    color: #212529;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }


.divpadre {
  height: 100vh;
  position: relative;
}

.divhijo {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}