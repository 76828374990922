.font-18 {
    font-size: 1.125rem;
}

.font-14 {
    font-size: 0.875rem;
}

.font-12 {
    font-size: 0.75rem;
}
