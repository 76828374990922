.input-buscar-container {
    position: relative;
}

.input-buscar-container .input-buscar-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
}

.input-buscar-container .input-buscar-icon.search-button {
    right: 6.5rem !important;
}
