
#root, .main-wrapper, body, html {
    height: 100%;
    width: 100%;
}

.general {
    padding-top: 1em;
    justify-content: center;
    align-items: center;
}

.conte-padre {
    width: 100%;
    display: flex;
    justify-content: center;
}

.nomb-cat {
    padding: 1.5rem;
}

.conte-itemb {
    width: 80em;
    border-width: 0.2rem 0.2rem 0;
    border-radius: 8px 8px 8px 8px;
    position: relative;
    padding: 1rem;
    border: 0.2rem solid #ececec;
    margin-right: 0;
    margin-left: 0;
    color: #212529;
}

.conte-itemb-perfilcliente {
    position: relative;
    padding: 1em;
    margin-right: 0;
    margin-left: 5px;
   
}

.conte-itemb-perfilcliente-coment {
    position: relative;
    padding: 1em;
    margin-right: 0;
    margin-left: 10px;
    color: #212529;
}

.conte-items {
    width: 90%;
    border-width: 0.2rem 0.2rem 0;
    border-radius: 8px 8px 8px 8px;
    padding: 1rem;
    border: 0.2rem solid #ececec;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    color: #212529;
}

.conte-center {
    width: 90%;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.pagi {
    display: flex;
    align-items: center;
}

.buscadores { 
    padding: 1.5rem;
    width: 80em;
    display: flex;
    position: relative;
    align-items: baseline;
}

.find {
    padding-left: 1em;
}

.find1 {
    padding-left: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* css para el navbar*/



.menu {
    padding: auto;
    margin-top: 100px;
    z-index: 1;
}


.me-auto {
    font-size: medium;
    font-weight: bold;
}

.link {
    color: white; 
}

.link:hover {
    color: #2699d6;
}
.navbar-toggler {
    color: white;
    border: solid white;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: url(../image/icom.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.navbar-brand {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 8px;
    white-space: nowrap;
}

.navbar-text {
    color: white;
    font-weight: bold;
    font-size: medium;
}
.navbar-text a {
    color: white;
    font-weight: bold;
    font-size: medium;
}

/* css para el footer*/

h6 {
    color: white;
    display: flex;
    justify-content: center;
}

/* css para vista cliente*/
.botoncentro{
    padding-top: 10px;
    display: flex;
    justify-content: center;
}

.accordion-button:not(.collapsed) {
    font-weight: bold;
}
.accordion-button {
    font-weight: bold;
}

h4 {
    display: flex;
    justify-content: center;
}
h2 {
    display: flex;
    justify-content: center;
}

/* css para Home*/


.newCli {
    margin-left: 7px;
    display: flex;
    float: right;
}

.conte-home {
    width: 95em;
    background-color: #eeeeee;
    border-width: 0.2rem 0.2rem 0;
    border-radius: 8px 8px 8px 8px;
    position: relative;
    padding: 1rem;
    border: 0.2rem solid #ececec;
    margin-right: 0;
    margin-left: 0;
    color: #212529;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.h4 {
    display: flex;
    justify-content: center;
}
.conte-centro {
    padding-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.izquierda {
    padding: 2px;
    display: list-item;
    text-align: right;
}
.derecha {
    padding: 2px;
    display: inline-table;
    text-align: left;
}

/* css para Sidebar*/

.sidebar {
    height: 100%;
    width: 300px;
    display: flex;
    position: fixed;    
    left: 0;
    top: 0;
    z-index: 3;
    }

/* css para input buscar por nombre*/
/* .input-buscar {
    margin-left: 20px;
} */

/* css para tabla*/

.rdt_TableCol {
    font-weight: bolder;
    background-color: #1c2e51;
    color: white;
}

.flex {
    display: flex;
}
.content {
    margin: 1em auto;
    
}

.info {
    margin-left: 50px;
}
.info h4 {
    justify-content: left;
}

.dtousr {
    margin-left: 10px;
}


.cardsp {
    margin: auto;
    text-decoration:none;
    color: #000000;
}

.containerGrafica{
    width: 100%;
    height: 100%;
}

.main-container container-fluid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
        

}
.main-wrapper  {
    min-height: 100vh;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    display: grid;
    grid-area: main;
    position: relative;
    grid-template-rows: 75px 1fr;
    grid-template-columns: min-content;
    grid-template-areas: "sidebar header""sidebar main";
}
.sidebar-menu {
    padding-top: 0;
    flex-direction:column;
    grid-area: sidebar;
    overflow-y: auto;
}

.main-container {
    grid-area: main;
    box-shadow: inset 2px 1px 9px 1px rgb(33 37 41 / 10%);
    overflow: auto;
    overflow-x: auto;
    overflow-y: auto;
    padding-top: 1rem;
}

.navbar, .sidebar {
    background-color: #1c2e51;
    width: 100%;
    position: relative;
    display: flex;
}

.navbar, .sidebar-menu {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: left;
    justify-content: space-between;
    padding-top: 0px;
    padding-bottom: 0px;
}
.sidebar-menu-collapse.collapse {
    display: block;
}
.sidebar-menu-collapse.show {
    width: 270px;
}
.navbar-collapse, .sidebar-menu-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.sidebar-menu-collapse:not(.show) {
    width: calc(1.875em + 2.5rem + 2px);
}
.sidebar-menu-collapse.collapse {
    display: block;
}
.collapse:not(.show) {
    display: none;
}
.navbar-collapse, .sidebar-menu-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}
*, :after, :before {
    box-sizing: border-box;
}

.sidebar-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}

/*REDIMENCIONES DE CONTAINER*/
.reciving {
 max-width: 1500px;
}

.werehouses {
    max-width: 1650px;
}

.imports {
    max-width: 2300px;
}

.coordinaciones {
 max-width: 1600px;
}

.pendingtitles {
    max-width: 1600px;
    justify-content: center;
}

.tablepending {
    max-width: 1120px;
}

.vehiculoedit {
    --bs-modal-width: 650px;
}

.modal , .emailsoli {
    --bs-modal-width: 800px;
}

.required {
    color: red;
}

@media screen and (max-width: 600px) {
    .navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
        flex-direction: column-reverse;
    }

    .namelog {
        display: none;
    }
}

.font-12 {
    font-size: 0.75rem;
}

.btnlink {
    background-color: white;
    opacity: 0.6;
    border: white;
    color: #000000;
}

.barraCoortable { 
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
