table {
    width: 100%;
    border-collapse: collapse;
  }

.header {
    background-color: #001427;
    color: white;
    font-weight: bold;
    padding-left: 5px;
    border: 1px solid lightslategray;
}

.reference {
    width: 30%;
    text-align: right;
    background-color: #e7e7e7;
    border-color: lightslategray;
    border-width: 1px;
    font-size:medium;
}

.option {
    width: 70%;
    background-color: #d9e5eb;
}

.itemslist {
    width: 14%;
    background-color: #e7e7e7;
    border-left: 1px solid lightslategray;
    border-right: 1px solid lightslategray;
    text-align: center;
    font-weight: bold;
}
.itemsinfo {
    width: 14%;
    background-color: #e7e7e7;
    text-align: center;
}

.infoAES {
    width: 100%;
    padding-left: 10px;
}

.infoAESItem {
    width: 100%;
    padding-left: 10px;
    text-align: center;
}
.footer-estatico {
    display: flex;
    justify-content: space-between;
            
    background-color: #001427;
  }

.ButtonSend {
    margin-top: 20px;
    padding: 5px; 
    border: 1px solid #000;
    border-radius: 5px;
    width: 50%;
    background-color: skyblue;
}


.ButtonValidate{
    margin-top: 20px;
    padding: 5px; 
    border: 1px solid #000;
    border-radius: 5px;
    width: 50%;
    background-color: rgb(44, 185, 44);
}