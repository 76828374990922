.coordinacion-table .rdt_TableHeadRow,
.coordinacion-table .rdt_TableRow {
  display: flex;
}

.coordinacion-table .rdt_TableHeadRow {
  position: sticky;
  top: 0;
  z-index: 100; /* Encabezado principal */
  background-color: white; /* Color de fondo para el encabezado principal */
}

.coordinacion-table .rdt_TableHeadRow .rdt_TableCol {
  background-color: #1C2E51; /* Color de fondo para el encabezado de todas las columnas */
  color: white; /* Color del texto del encabezado */
  z-index: 101; /* Encabezado de todas las columnas */
}

.coordinacion-table .rdt_TableHeadRow .rdt_TableCol:first-child {
  position: sticky;
  left: 0;
  z-index: 103; /* Encabezado de la primera columna */
  background-color: white;
  color: black;
  border-right: 1px solid #e3e3e3;
}

.coordinacion-table .rdt_TableRow .rdt_TableCell:first-child {
  position: sticky;
  left: 0;
  z-index: 102; /* Celdas de la primera columna */
  background-color: white;
  border-right: 1px solid #e3e3e3;
}

.coordinacion-table .rdt_TableRow .rdt_TableCell {
  background-color: white; /* Fondo blanco para las celdas de datos */
}

.coordinacion-table .rdt_TableRow {
  min-height: 38px !important;
}

.coordinacion-table .rdt_TableRow:hover .rdt_TableCell {
    background-color: #f0f0f0; /* Cambia el color de fondo de todas las celdas de la fila cuando hay hover */
    cursor: pointer; /* Cambia el cursor al puntero cuando hay hover */
}

/************/

/*.coordinacion-table .rdt_TableHeadRow,
.coordinacion-table .rdt_TableRow {
  display: flex;
}

.coordinacion-table .rdt_TableCol:first-child,
.coordinacion-table .rdt_TableCell:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #1C2E51; /* Asegúrate de que el fondo coincide con el de la tabla */
/*}

.coordinacion-table .rdt_TableHeadRow .rdt_TableCol:first-child {
    z-index: 3; /* Asegúrate de que el encabezado está por encima de las celdas */
/*}
  
.coordinacion-table .rdt_TableRow .rdt_TableCell:first-child {
    z-index: 2; /* Asegúrate de que las celdas están debajo del encabezado */
/*}

.coordinacion-table {
    .rdt_TableRow {
        min-height: 38px !important;
    }
}*/

td:has(.cell-bg-yellow) {
  background: yellow !important;
}

td:has(.cell-bg-green) {
  background: #00913f !important;
  color: black;
}
td:has(.cell-bg-light-red) {
  background: #ccaaa9 !important;
  color: black;
}
td:has(.cell-bg-brown) {
  background: #795027 !important;
  color: white;
}
td:has(.cell-bg-cyan) {
  background: #00BFFF !important;
  color: white;
}
td:has(.cell-bg-cyan-light) {
  background: #00FFFF !important;
  color: black;
}
td:has(.cell-bg-red) {
  background: #FF0000 !important;
  color: white;
}
td:has(.cell-bg-pink) {
  background: #FF00FF !important;
  color: white;
}
td:has(.cell-bg-pink-dark) {
  background: #800080 !important;
  color: white;
}
td:has(.cell-bg-orange) {
  background: #FFA500 !important;
  color: black;
}
td:has(.cell-bg-white) {
  background: #FFFFFF !important;
  color: black;
}
td:has(.cell-bg-gray) {
  background: #808080 !important;
  color: white;
}
td:has(.cell-bg-green-dark) {
  background: #006400 !important;
  color: white;
}
td:has(.cell-bg-blue-dark) {
  background: #1414b8 !important;
  color: white;
}
td:has(.cell-bg-black) {
  background: #000000 !important;
  color: white;
}
td:has(.cell-bg-blue) {
  background: #0000ff !important;
  color: white;
}

.cell-editable {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  cursor: pointer;
}

.cell-editable:hover .icon {
  opacity: 0.6 !important;
}

.cell-editable .icon {
  opacity: 0;
  transition: 0.4s all ease-in-out;
}