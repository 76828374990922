@import "scss/index.css";

.cursor-pointer {
    cursor: pointer !important;
}

.badge {
    --bs-badge-padding-x: 0.65rem;
    --bs-badge-padding-y: 0.40rem;
    --bs-badge-font-size: 0.70rem;
    --bs-badge-border-radius: 4px;
}

/* Elimina las flechas en Chrome, Safari, Edge y Opera */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Elimina las flechas en Firefox */
.no-arrows {
  -moz-appearance: textfield;
  appearance: textfield;
}

.alert-gray {
    background-color: #f3f3f3;
    border: 1px solid #979797;

    .ant-alert-icon {
        color: #636363;
    }
}

.alert-black {
    background-color: #474747;
    border: 1px solid #000000;
    color: white;

    .ant-alert-icon {
        color: #fff;
    }
}
